import React from "react";

export function Logo() {
  return (
    <svg
      width="94"
      height="19"
      viewBox="0 0 94 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.3092 5.75433C37.3092 2.37495 34.6215 0 30.9666 0C27.7581 0 25.5167 1.79292 25.0889 4.49642L28.3067 4.95638C28.4555 3.64218 29.4879 2.87247 30.9666 2.87247C32.5476 2.87247 33.5985 3.8769 33.8031 5.11601C33.2308 5.28776 32.5683 5.42054 31.9924 5.53597C31.8758 5.55933 31.7628 5.58198 31.6548 5.6041C28.5393 6.17672 25.34 6.78688 25.34 10.354C25.34 12.7759 27.1256 14.4562 29.99 14.4562C31.8314 14.4562 33.2636 13.7616 34.1843 12.391V14.1746H37.7091C37.4022 13.3767 37.2999 11.9029 37.2999 10.2508V5.75433H37.3092ZM30.5854 11.6682C29.4136 11.6682 28.744 11.0956 28.744 10.1475C28.744 9.01166 29.8412 8.65497 31.6082 8.34519C32.5754 8.18561 33.2823 7.97906 33.831 7.79132C33.8221 8.0169 33.8131 8.08635 33.7877 8.28314L33.7845 8.3076C33.5241 10.3258 32.2966 11.6682 30.5854 11.6682Z"
        fill="#171C2B"
      />
      <path
        d="M61.6845 0.282022L58.6713 8.2705L58.0204 10.1667L57.3692 8.2705L54.356 0.282022H48.7481L45.0653 9.67857L41.4384 0.282022H38.0161L43.4379 13.424L43.1032 14.2032C42.6661 15.1794 42.1358 15.8553 41.0849 15.8553C40.3223 15.8553 39.5785 15.5173 38.9647 15.1043L37.9416 18.127C38.6298 18.6151 39.7552 19 40.9828 19C43.717 19 45.1025 17.4042 45.9674 15.2827L51.5197 1.88724L56.4022 14.175H59.6479L65.1441 0.291418H61.6845V0.282022Z"
        fill="#171C2B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.8867 0C75.6996 0 78.564 2.86306 78.564 6.60853C78.564 7.02157 78.5083 7.71624 78.4711 8.04479H68.0922C68.4456 10.1381 69.9615 11.4805 72.054 11.4805C73.7466 11.4805 74.909 10.7107 75.2624 9.55611L78.5267 10.0818C77.7269 12.8134 75.5137 14.4562 72.1471 14.4562C67.8784 14.4562 64.8837 11.4617 64.8837 7.25625C64.8837 3.06958 67.9528 0 71.8867 0ZM71.8401 2.84428C69.9987 2.84428 68.7433 3.82057 68.2318 5.39761H75.1416C75.0579 3.90505 73.7094 2.84428 71.8401 2.84428Z"
        fill="#171C2B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.9807 11.4899C84.8882 11.4899 83.3723 10.1475 83.0189 8.05419H93.3976C93.4441 7.71625 93.5 7.02157 93.5 6.60853C93.5 2.86306 90.6357 0 86.8227 0C82.8888 0 79.8198 3.06958 79.8198 7.25625C79.8198 11.4617 82.8144 14.4562 87.0831 14.4562C90.4497 14.4562 92.663 12.8134 93.4628 10.0818L90.1985 9.55611C89.8358 10.7201 88.6826 11.4899 86.9807 11.4899ZM86.7761 2.84428C88.6454 2.84428 89.994 3.90505 90.0777 5.39761H83.1769C83.6791 3.82057 84.9347 2.84428 86.7761 2.84428Z"
        fill="#171C2B"
      />
      <path
        d="M19.3883 11.0866C19.6673 11.0866 19.8997 10.8707 19.9183 10.5891L20.5508 0.282022H23.6383L23.0059 11.2087C22.9036 12.8702 21.5367 14.175 19.8906 14.175H18.328C17.0167 14.175 15.8543 13.3208 15.4451 12.0629L12.8411 4.14952C12.7295 3.78342 12.4783 3.5112 12.0691 3.5112C11.6599 3.5112 11.409 3.78342 11.2974 4.14952L8.69334 12.0629C8.28414 13.3208 7.12155 14.175 5.81026 14.175H4.24792C2.59252 14.175 1.22534 12.8702 1.13234 11.2087L0.5 0.282022H3.57835L4.20138 10.5891C4.21998 10.8707 4.45254 11.0866 4.73154 11.0866H5.29871C5.57771 11.0866 5.83827 10.8989 5.92197 10.6267L8.35844 3.20141C8.86064 1.54927 10.3486 0.441587 12.0598 0.441587C13.771 0.441587 15.259 1.54927 15.7612 3.20141L18.1979 10.6267C18.2816 10.8989 18.5326 11.0866 18.8209 11.0866H19.3883Z"
        fill="#171C2B"
      />
    </svg>
  );
}
