"use client";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Alert, Button, Divider, Flex, Form, Input, Typography } from "antd";
import { useSearchParams } from "next/navigation";
import React, { CSSProperties, useEffect } from "react";
import { Logo } from "@/app/(unauthorized)/components/logo";
import { SIGN_IN } from "@/app/(unauthorized)/_mutations";

const { Text } = Typography;

const publicBackendOrigin = process.env.NEXT_PUBLIC_BACK_END_ORIGIN;
export default function SignInPage() {
  const [signIn, { loading, error }] = useMutation(SIGN_IN);
  const [googleAuthUrl, setGoogleAuthUrl] = React.useState<string>("");

  useEffect(() => {
    const currentUrl = new URL(document.location.href);
    const backUrl = currentUrl.searchParams.get("back_url");

    const googleAuthLink = new URL(
      publicBackendOrigin + "/auth/google/login",
      currentUrl,
    );
    if (backUrl) {
      googleAuthLink.searchParams.append("back_url", backUrl);
    }
    setGoogleAuthUrl(googleAuthLink.toString());
  }, []);

  const searchParams = useSearchParams();
  const backUrl = searchParams.get("back_url");
  const googleIconStyles: CSSProperties = {
    width: "1.5rem",
    height: "1.5rem",
    display: "block",
    backgroundImage:
      // eslint-disable-next-line max-len
      "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=)",

    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <>
      <Flex className="" vertical justify="start">
        <div className="w-full min-h-[30px] sm"></div>
        <div className="w-[328px] m-auto">
          <div className="w-full flex justify-center mt-10 mb-8">
            <Logo />
          </div>
          {error && <Alert type={"error"} message={error.message} />}
          <Form
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={(values) => {
              signIn({
                variables: {
                  email: values.username,
                  password: values.password,
                },
              }).then(
                () => {
                  if (backUrl) {
                    return document.location.replace(backUrl);
                  }
                  document.location = "/";
                },
                // TODO: // add error message
                () => {},
              );
            }}
          >
            <Form.Item
              style={{
                textAlign: "center",
              }}
            >
              <Text type="secondary">
                Real-Time Customer Satisfaction Analytics
              </Text>
            </Form.Item>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <div className="text-right">
                <Button type="link" href="/remind">
                  Forgot password?
                </Button>
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                loading={loading}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
          <>
            <Divider>Or</Divider>
            <div>
              <Button
                href={googleAuthUrl}
                icon={<i style={googleIconStyles} />}
                size="large"
                style={{ display: "inline-flex" }}
                className="w-full items-center justify-center"
              >
                Sign in with Google{" "}
              </Button>
            </div>
          </>
        </div>
        <div className="w-full min-h-[30px]"></div>
      </Flex>
    </>
  );
}
