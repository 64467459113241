import gql from "graphql-tag";

export const SIGN_IN = gql(/* GraphQL */ `
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      id
    }
  }
`);

export const SET_PASSWORD = gql(/* GraphQL */ `
  mutation setNewPassword($token: String!, $password: String!) {
    setNewPassword(token: $token, password: $password)
  }
`);

export const RESET_PASSWORD = gql(/* GraphQL */ `
  mutation resetPassword($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`);

export const SIGN_OUT = gql(/* GraphQL */ `
  mutation signOut {
    signOut {
      id
    }
  }
`);
